// src/pages/PlaylistPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import ReactGA from "react-ga4";
import menuIcon from "../../assets/menu-icon.png";
import greenBlob from "../../animations/greenblob.json";
import Lottie from 'react-lottie';
import { Link, useHistory } from "react-router-dom";

// Import the HeartIconWithCount component
import HeartIconWithCount from './HeartIconWithCount';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Styled Components
const AppWrapper = styled.div`
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
`;

// Uncomment and define if you plan to use the SoundBar
// const SoundBar = styled.div`
//   background: url(${soundBarImg}) no-repeat center center;
//   background-size: cover;
//   border: none;
//   margin-left: 15%;
//   width: 70%;
//   height: 40px;
//   cursor: pointer;
//   left: 0px;
// `;

const MenuButton = styled.button`
  background: url(${menuIcon}) no-repeat center center;
  background-size: cover;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: fixed;
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 16px;
  &:hover {
    background-color: #292929;
  }
`;

const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 5%;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.$show ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

const Header = styled.header`
  top: 0;
  left: ${(props) =>
    props.$menuOpen ? "250px" : "0"}; // Adjust position based on menu state
  right: 0;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100%; // Adjust width based on menu state
  transition: transform 0.3s ease-in-out; // Smooth transition for the movement
`;

const PageContainer = styled.div`
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  margin-top: 64px;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const StyledHeader = styled.header`
  background-color: #121217;
  background-size: cover;
  backgroun-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 40px;
  border-bottom: 1px solid rgba(229, 232, 235, 1);
  font-size: 16px;
  color: #9ea3b8;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #292b38;
  padding: 8px 16px;
  border-radius: 12px;
  color: #fff;
`;

const Logo = styled.img`
  width: 40px;
`;

const SearchIcon = styled.img`
  width: 24px;
`;

const ContentContainer = styled.main`
  width: 100%;
  max-width: 100%;
  margin-top: 112px;
  padding: 20px;
  padding-top: 0px;
  background-color: #1E1E1E; /* Darker background for the content */
  border-radius: 16px; /* Smooth corners */
  overflow-x: hidden;
  box-sizing: border-box;
`;

const TitleContainer = styled.section`
  display: flex; /* Ensure items are arranged in a row */
  flex-direction: row; /* Default is row, but explicit here for clarity */
  align-items: center; /* Vertically align the blob and the text */
  gap: 8px; /* Maintain spacing between items */
  padding: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for lift */
  position: fixed;
  background-color: #141414;
  top: 65px;
  width: 100%;
`;

const Breadcrumb = styled.div`
  display: flex;
  flex-grow: 1;
  color: #9ea3b8;
  gap: 10px;
  flex-direction: column; /* Stack items vertically */
`;

const BlobLottieWrapper = styled.div`
  width: 75px; /* Adjust this to control the size */
  height: 75px; /* Adjust this to control the size */
  margin-right: 10px; /* Adds space between the blob and the breadcrumb */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubheaderText = styled.span`
  color: #fff;
  font-size: 14px;
  margin: 0px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const HeaderText = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -0.5px;
  margin: 0px;
`;

const PageTitle = styled.h2`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.8px;
  margin: 0px;
`;

const QueueSection = styled.section`
  margin-top: 12px;
  width: 100%;
  overflow-x: hidden;
  max-width: 100%;
  box-sizing: border-box; 
`;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure all items are vertically centered */
  width: 100%;
  padding: 10px 0;
`;

const SongDetails = styled.div`
  display: flex;
  align-items: center; /* Align image and song info vertically */
  gap: 14px; /* Space between the image and song info */
  width: 70%; /* Ensure it doesn't take too much space so the heart icon stays on the right */
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 90%; /* Ensure it doesn't overflow the parent container */
`;

const SongTitle = styled.h3`
  color: #fff;
  font-size: 16px;
  max-width: 100%;
  margin: 0; /* Remove margin to prevent extra space */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis when text is clipped */
`;

const SongDuration = styled.p`
  color: #9ea3b8;
  font-size: 12px;
  margin: 0; /* Remove margin to prevent extra space */
  margin-top: 8px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis when text is clipped */
`;

// Removed HeartContainer, LikeCountCircle, HeartIconSVG, Count, and related styled-components

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f00;
  padding: 10px 16px;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.21px;
  max-height: 40px;
  margin-right: 20px;
  margin-top: 15px;
`;

const PlayIcon = styled.img`
  width: 20px;
`;

const SmallImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 16px;
`;

const Pagination = styled.nav`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  font-size: 14px;
  color: #fff;
`;

const PageNumber = styled.button`
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #292b38;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const SecondaryLogo = styled.img`
  width: 18px;
`;

const FooterImage = styled.img`
  width: 130px;
  margin: 12px auto 0;
  display: block;
`;

// SongList Component
const SongList = ({ venueId, sessionId }) => {
  const [songQueueData, setSongQueueData] = useState([]);

  useEffect(() => {
    // Track page view
    ReactGA.event('pp_view', {
      category: "User",
      action: "pp_view",
      label: venueId,
    });

    // Log scroll event
    const handleScroll = () => {
      const scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
      ReactGA.event('pp_scroll', {
        category: "User",
        action: "pp_scroll",
        label: venueId,
        scroll_depth: `Scroll Depth: ${scrollDepth.toFixed(2)}%`,
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [venueId]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleSongTap = (song) => {
    const songDetails = formatSongDetails(song);
    ReactGA.event('pp_song_tap', {
      category: "User",
      action: "pp_song_tap",
      label: venueId,
      song_details: formatSongDetails(songDetails),
    });
    // Implement song tap functionality
  };

  const formatSongDetails = (song) => {
    return JSON.stringify({
      song_id: song.id,
      song_name: song.name,
    });
  };

  const [fetchedIds, setFetchedIds] = useState(() => {
    const storedIds = localStorage.getItem('fetchedIds');
    return storedIds ? JSON.parse(storedIds) : [];
  });

  const itemsPerPage = 8;

  useEffect(() => {
    const fetchsongQueueData = () => {
      axios.get(`${backendUrl}/queue/${venueId}`)
        .then(response => {
          setSongQueueData(response.data);
        })
        .catch(error => {
          console.error('Error fetching song queue:', error);
        });
    };

    // Fetch song queue initially
    fetchsongQueueData();

    // Poll for song queue every 5 seconds
    const intervalId = setInterval(fetchsongQueueData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [venueId]);

  return Array.isArray(songQueueData) && songQueueData.length > 0 ? (
    <>
      {songQueueData.map((song) => (
        <SongItem key={song.songObj.id} onClick={() => handleSongTap(song)}>
          <SongDetails>
            <SmallImage src={song.songObj.album.images[0].url} alt="Video Thumbnail" />
            <SongInfo>
              <SongTitle>{song.songObj.name}</SongTitle>
              <SongDuration>{song.songObj.artists[0].name}</SongDuration>
            </SongInfo>
          </SongDetails>
          <HeartIconWithCount
            venueId={venueId}
            entryId={song.entryId} // Pass the entryId for backend reference
            initialLikes={song.likesCount || 0} // Initialize with likes from the backend
            sessionId={sessionId}
            isInteractive={true} // Enable interactive mode
          />
          {/* Uncomment if you plan to use the PlayButton
          <PlayButton onClick={() => handleClick(song.id.videoId)}>
            <PlayIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8ea19aa913e33e9ec317c707adcc5d83de3d1dea97289fe0231dc27409fccb3?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Play" loading="lazy" />
            Play
          </PlayButton> 
          */}
        </SongItem>
      ))}
      {/* Uncomment and implement Pagination if needed
      <Pagination>
        {pageNumbers.map((number) => (
          <PageNumber key={number} onClick={() => handlePageChange(number)}>
            {number}
          </PageNumber>
        ))}
      </Pagination>
      */}
    </>
  ) : (
    <li style={{ color: 'wheat', marginTop: 25 }}>No songs in the queue</li>
  );
}

// PlaylistPage Component
const PlaylistPage = ({ venueId, sessionId }) => {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  const [venueName, setVenueName] = useState('The Venue');

  useEffect(() => {
    const fetchVenueName = () => {
      axios.get(`${backendUrl}/api/venue/name/${venueId}`)
        .then(response => {
          setVenueName(response.data.venueName);
        })
        .catch(error => {
          console.error('Error fetching venue name:', error);
        });
    };

    if (venueId) {
      fetchVenueName();
    }
  }, [venueId]);

  const handleVenueNameTap = () => {
    ReactGA.event('pp_venue_name_tap', {
      category: "User",
      action: "pp_venue_name_tap",
      label: venueId,
    });
    // Implement venue name tap functionality
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <AppWrapper>
      {/* Uncomment and use the side menu if needed
      <SideMenu $show={menuOpen}>
        <MenuItem to="/search">Search</MenuItem>
        <MenuItem to="/playlist">Queue</MenuItem>
      </SideMenu>
      <Header $menuOpen={menuOpen}>
        <MenuButton onClick={toggleMenu} />
      </Header>
      */}

      <PageContainer $menuOpen={menuOpen}>
        {/* Uncomment and use the styled header if needed
        <StyledHeader>
          <NavBar>
            <SearchContainer>
              <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/f36fef528f91c54f065197f159904d7674976b57b3cb0940a5d1fdbb70d76a6c?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Logo" loading="lazy" />
            </SearchContainer>
            <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/84ae2031613417c1aa84c0b715970a00b15efc187b1e7daddd56d1294733d7c5?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Secondary Logo" loading="lazy" />
          </NavBar>
        </StyledHeader>
        */}

        <TitleContainer>
          <BlobLottieWrapper>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: greenBlob,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={75}  // Set this to match the wrapper size
              width={75}
            />
          </BlobLottieWrapper>
          <Breadcrumb onClick={handleVenueNameTap}>
            <SubheaderText>Playing Now at</SubheaderText>
            <HeaderText>{venueName}</HeaderText>
          </Breadcrumb>
        </TitleContainer>
        <ContentContainer>
          <QueueSection>
            <SongList venueId={venueId} sessionId={sessionId} />
          </QueueSection>
        </ContentContainer>
        {/* Uncomment if you plan to use the footer image
        <FooterImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/e00fbd34720eabcafd78b6e0491cbd658699a3375fa74dc9f1f4a4f969f102cf?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&" alt="Footer" loading="lazy" />
        */}
      </PageContainer>
    </AppWrapper>
  );
}

export default PlaylistPage;
