import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as PlaylistAddIcon } from '../../assets/playlist-add-icon.svg';
import styled, { keyframes } from "styled-components";
import menuIcon from "../../assets/menu-icon.png";
import soundBarImg from "../../assets/soundbar-img.png";
import uiBackgroundImg from "../../assets/uibackground.jpg";
import { FaPlus, FaCheck } from "react-icons/fa";
import ReactGA from "react-ga4";
import axios from 'axios';
import he from "he";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { useMenu } from '../../context/MenuContext';
import Lottie from 'react-lottie';
import musicbeats from '../../animations/musicbeats.json';
import successanimation from '../../animations/success.json';
import timeoutImg from "../../assets/timeout.png"
import FreezeMode from '../FreezeMode'; // Adjust the path based on your file structure


const trendingSongsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/spotify/playlist/37i9dQZF1DWTwzVdyRpXm1/tracks`;
const topSongsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/spotify/playlist/37i9dQZEVXbMDoHDwVN2tF/tracks`; // international hits
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const viralIndiaUrl = `${process.env.REACT_APP_BACKEND_URL}/api/spotify/playlist/37i9dQZEVXbLZ52XmnySJg/tracks`; // top 50 - india

const SpotifySearchComponent = ({ venueId, sessionId }) => {
  const history = useHistory();

  useEffect(() => {
    // ReactGA4.pageview(window.location.pathname + window.location.search);
    ReactGA.event('dp_view',
      {
        category: "User",
        action: "dp_view",
        label: venueId,
      });

    const handleScroll = () => {
      const scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
      ReactGA.event('dp_scroll', {
        category: "User",
        action: "dp_scroll",
        label: venueId,
        scroll_depth: `Scroll Depth: ${scrollDepth.toFixed(2)}%`,
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [venueId]);

  const playPreview = (previewUrl) => {
    const audio = new Audio(previewUrl);
    audio.pause();
    audio.play();
  };

  const formatSongDetails = (song) => {
    // const allArtists = song.artists.map(artist => artist.name).join(", ");
    return JSON.stringify({
      song_id: song.id,
      song_name: song.name,
      // song_artists: allArtists,
      // song_album: song.album.name,
    });
  };



  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [selectedSongsId, setSelectedSongsId] = React.useState([]);
  const [selectedSongs, setSelectedSongs] = React.useState([]);
  const [songSource, setSongSource] = useState("landing"); // Default to 'landing'
  const [isSearching, setIsSearching] = useState(false); // State to track if searching
  const { menuOpen } = useMenu();
  const [hotHits, setHotHits] = React.useState([]);
  const [recentlyPlayedData, setRecentlyPlayedData] = useState([]);
  const [trendingSongsData, setTrendingSongsData] = useState([]);
  const [topSongsData, setTopSongsData] = useState([]);
  const [viralIndiaSongsData, setViralIndiaSongsData] = useState([]);
  const [livePLaylist, setlivePLaylist] = useState([]);
  const [isBarActive, setIsBarActive] = useState(false);
  const [barPosition, setBarPosition] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [venueName, setVenueName] = useState('The Venue');
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [repeatedData, setRepeatedData] = useState([]);
  const scrollContainerRef = useRef(null);
  const [trendingSlice, setTrendingSlice] = useState([]);
  const [internationalSlice, setInternationalSlice] = useState([]);

  // Freeze mode states
  // const [songAddCount, setSongAddCount] = useState(0);
  // const [isFreezeModeActive, setIsFreezeModeActive] = useState(false);

  // useEffect(() => {
  //   // Check if freeze mode is active on component mount
  //   const freezeModeExpiresAt = sessionStorage.getItem('freezeModeExpiresAt');
  //   if (freezeModeExpiresAt && new Date().getTime() < freezeModeExpiresAt) {
  //     setIsFreezeModeActive(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isFreezeModeActive) {
  //     const freezeModeExpiresAt = sessionStorage.getItem('freezeModeExpiresAt');
  //     if (freezeModeExpiresAt) {
  //       const remainingTime = freezeModeExpiresAt - new Date().getTime();
  //       const timer = setTimeout(() => {
  //         setIsFreezeModeActive(false);
  //         setSongAddCount(0);
  //         sessionStorage.removeItem('freezeModeExpiresAt');
  //       }, remainingTime);

  //       return () => clearTimeout(timer);
  //     }
  //   }
  // }, [isFreezeModeActive]);

  const handleOfflineClick = (event) => {
    event.stopPropagation(); // Prevents the BottomBar onClick from triggering
    setIsModalOpen(true);    // Opens the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };


  const handleBarClick = () => {
    setBarPosition(100); // Set the bar position to fully reveal the playlist
    setIsBarActive(true);
    ReactGA.event('dp_bottombar_tap', {
      category: "User",
      action: "dp_bottombar_tap",
      label: venueId,
      bar_state: isBarActive ? "Playing" : "Offline",
    });
    setTimeout(() => {
      history.push(`/playlist?venueId=${venueId}`);
    }, 500);  // Match this to your CSS transition duration
  };

  const handleTouchStart = (e) => {
    setTouchStartY(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const currentTouchY = e.targetTouches[0].clientY;
    const distanceMoved = touchStartY - currentTouchY;

    if (distanceMoved > 0) {
      setBarPosition(distanceMoved);
    }
  };

  const handleTouchEnd = () => {
    if (barPosition > 100 || barPosition === 100) {  // Threshold to complete the swipe up
      setIsBarActive(true);
      setTimeout(() => {
        history.push(`/playlist?venueId=${venueId}`);
      }, 500);  // Duration of the animation
    } else {
      setBarPosition(0);  // Reset the bar position if the swipe wasn't far enough
    }
  };

  useEffect(() => {
    const barElement = document.getElementById('bottomBar');
    if (barElement) {  // Check if the BottomBar exists
      barElement.addEventListener('touchstart', handleTouchStart);
      barElement.addEventListener('touchmove', handleTouchMove);
      barElement.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (barElement) {  // Check again before removing event listeners
        barElement.removeEventListener('touchstart', handleTouchStart);
        barElement.removeEventListener('touchmove', handleTouchMove);
        barElement.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [touchStartY, touchEndY, barPosition]);

  useEffect(() => {
    if (barPosition > 0) {
      document.body.style.overflow = 'hidden';  // Disable page scrolling
    } else {
      document.body.style.overflow = 'auto';  // Enable page scrolling
    }
  }, [barPosition]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const trendingSongsResponse = await fetch(trendingSongsUrl);
        const trendingSongs = await trendingSongsResponse.json();

        // const recentlyPlayedResponse = await fetch(`${backendUrl}/recents/${venueId}`);
        // const recentlyPLayed = await recentlyPlayedResponse.json();
        const recentlyPLayed = [];

        const topSongsResponse = await fetch(topSongsUrl);
        const topSongs = await topSongsResponse.json();

        const viralIndiaSongsResponse = await fetch(viralIndiaUrl);
        const viralSongs = await viralIndiaSongsResponse.json();

        setRecentlyPlayedData(recentlyPLayed.reverse());
        setTrendingSongsData(trendingSongs);
        setTopSongsData(topSongs);
        setViralIndiaSongsData(viralSongs);
      } catch (error) {
        console.error("Error fetching daily data:", error);
      }
    };

    const fetchVenueName = () => {
      axios.get(`${backendUrl}/api/venue/name/${venueId}`)
        .then(response => {
          setVenueName(response.data.venueName);
        })
        .catch(error => {
          console.error('Error fetching venue name:', error);
        });
    };

    if (venueId) {
      fetchVenueName();
    }

    const fetchLivePlaylist = () => {
      // axios.get('http://localhost:5000/queue')
      axios.get(`${backendUrl}/queue/${venueId}`)
        .then(response => {
          setlivePLaylist(response.data);
        })
        .catch(error => {
          console.error('Error fetching song queue:', error);
        });
    };

    const intervalId = setInterval(fetchLivePlaylist, 5000);  // Poll every 5 seconds

    fetchData();
    fetchLivePlaylist();

    return () => clearInterval(intervalId);
  }, [venueId]);

  useEffect(() => {
    const fetchPlayerState = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/player/state/${venueId}`);
        return response.data.playerState;
      } catch (error) {
        console.error("Error fetching player state:", error);
        return 'off';  // Assume 'off' in case of error
      }
    };

    const updateBottomBar = async () => {
      const playerState = await fetchPlayerState();

      if (playerState === 'playing') {
        console.log("Setting isBarActive to true");  // Add this log to check
        setIsBarActive(true);  // Show Lottie animation
      } else {
        console.log("Setting isBarActive to false");  // Add this log to check
        setIsBarActive(false);  // Hide Lottie animation
      }
    };

    // Poll the backend periodically to fetch player state
    const intervalId = setInterval(updateBottomBar, 5000);  // Poll every 5 seconds

    // Initial state fetch
    updateBottomBar();

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [venueId]);


  const toggleMenu = () => {
    // setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    // Track menu toggle event
    ReactGA.event({
      category: "Navigation",
      action: "Toggle Menu",
      label: menuOpen ? "Close Menu" : "Open Menu",
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = (e) => {
    e.preventDefault(); // Prevent the button click from submitting the form
    setSearchQuery("");
    setSearchResults([]);
    setSelectedSongsId([]);
    setSelectedSongs([]);
    // Track search clear event
    ReactGA.event('dp_search_cross_tap',
      {
        category: "User",
        action: "dp_search_cross_tap",
        label: venueId,
      });

    setSongSource("landing"); // Update source to 'search'
    setIsSearching(false);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await fetch(
      //   `https://scanthevibev1.el.r.appspot.com/api/youtube/search?q=${searchQuery}`
      // );
      //   const response = await fetch(`/api/youtube/search?query=${searchQuery}`);
      if (searchQuery) {
        // const response = await fetch(`http://localhost:5000/api/spotify/search?q=${searchQuery}&type=track`);
        // Track search submit event
        ReactGA.event('dp_search_result_generated',
          {
            category: "User",
            action: "dp_search_result_generated",
            label: venueId,
            input_values: JSON.stringify({ searchQuery }),

          });
        const response = await fetch(`${backendUrl}/api/spotify/search?q=${searchQuery}&type=track`);
        const data = await response.json();
        console.log("data: " + data);
        // const cleanedResults = cleanSearchResults(data.items);
        setSearchResults(data.tracks.items); // Adjust based on your actual API response structure
        // console.log("searchresults: " + searchResults);
        setIsSearching(true); // Indicate search results are present
        setSongSource("search"); // Update source to 'search'
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      history.replace("/error");
    }
  };

  // const fetchLyrics = async (trackName, artistName) => {
  //   try {
  //     const response = await fetch(`http://localhost:5000/api/lyric/musixmatch?trackName=${trackName}&artistName=${artistName}`);
  //     return (response.data.lyrics);
  //     // setLanguage(response.data.language);
  //   } catch (error) {
  //     console.error('Error fetching lyrics:', error);
  //   }
  // };

  const handleToggleSelect = (event, song, source) => {
    event.preventDefault();
    event.stopPropagation();

    var add_tap_source = "_add_tap";
    var triggerGAFlag = true;
    const songId = song.id;
    const index = selectedSongs.findIndex(
      (song) => song.id === songId
    );

    switch (source) {
      case "youmaylike": add_tap_source = "dp_may_like" + add_tap_source; break;
      case "trendingnow": add_tap_source = "dp_trending_now" + add_tap_source; break;
      case "internationalhits": add_tap_source = "dp_international_hits" + add_tap_source; break;
      case "search": add_tap_source = "dp_search_results" + add_tap_source; break;
      default: triggerGAFlag = false; break;
    }

    console.log("song + index: " + song + " " + index);
    // if (index !== -1) {
    //   setSelectedSongs((prevState) =>
    //     prevState.filter((s) => s.id !== songId)
    //   );
    // } else {
    //   setSelectedSongs((prevState) => [...prevState, song]);
    //   console.log(selectedSongs);
    // }

    if (index !== -1) {
      setSelectedSongs([]);
    } else {
      setSelectedSongs([song]);
    }

    console.log("song"+song);

    // Track song selection event
    if (triggerGAFlag) {
      ReactGA.event(add_tap_source, {
        category: "User",
        action: add_tap_source,
        label: venueId,
        song_details: formatSongDetails(song),
      });
    }

    // setSelectedSongsId((prev) => {
    //   if (prev.includes(song.id.videoId)) {
    //     return prev.filter((songName) => songName.id.videoId !== song.id.videoId); // Remove id if already selected
    //   } else {
    //     return [...prev, song]; // Add id if not already selected
    //   }
    // });
  };

  const handleSendToQueue = async () => {
    if (selectedSongs.length === 0) return;

    const timestamp = new Date().toISOString();
    console.log("song structure selectedSongs: "+selectedSongs[0].id);
    // Prepare the song data to match your backend schema
    const songData = selectedSongs.map((song) => ({
      id: song.id,  // Unique song ID from Spotify
      songObj: song,    // Full song object
      addedToPlaylistAt: timestamp,  // Current time
      playbackTimestamp: null,  // Initially null
      suggestedBy: 'User',  // Example value for suggested by (can be customized)
      user: {
        userId: null,  // If you have a user ID, add it here
        sessionId: sessionId || 'defaultSession',  // Example session ID
      },
      isPlayed: false,  // Initially false
      addedFrom: songSource,  // Source of the song (e.g., search, discovery)
      venueId: venueId,  // Current venue ID
      songEndingTimestamp: null  // Initially null
    }));

    //change this line for environment change :NOTE
    const response = await fetch(`${backendUrl}/queue/add/${venueId}`, {
      // const response = await fetch("http://localhost:5000/queue/add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ song: songData }),
    });
    if (response.status === 200) {
      // toast.success("Song added! Keep the vibe going.");
      setShowSuccessAnimation(true);  // Show success animation
      setTimeout(() => {
        setShowSuccessAnimation(false);  // Hide after 2 seconds
      }, 2000);  // Duration for how long to show the animation
      // Track successful song addition event
      ReactGA.event('dp_send_suggestion_tap_success',
        {
          category: "User",
          action: "dp_send_suggestion_tap_success",
          label: venueId,
          songDetails: selectedSongs.map(formatSongDetails),

        });
        
        // Update song add count and check for freeze mode activation in App.js
        // setSongAddCount((prevCount) => {
        //   const newCount = prevCount + 1;
        //   if (newCount >= 3) {
        //     // Activate freeze mode
        //     const freezeDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
        //     const freezeModeExpiresAt = new Date().getTime() + freezeDuration;
        //     sessionStorage.setItem('freezeModeExpiresAt', freezeModeExpiresAt);
        //     setIsFreezeModeActive(true); // Call setIsFreezeModeActive from props
        //   }
        //   return newCount;
        // });
        
    } else {
      toast.error("Uh Oh! Lets try that again in a bit.");
      history.replace("/error");
      // Track failed song addition event
      ReactGA.event('dp_send_suggestion_tap_failure',
        {
          category: "User",
          action: "dp_send_suggestion_tap_failure",
          label: venueId,
        });
    }
    const data = await response.json();
    console.log(data);
    setSelectedSongsId([]);
    setSelectedSongs([]);
  };

  useEffect(() => {
    if (menuOpen) {
      // Disable scrolling on the body by adding overflow hidden
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling by removing the overflow style
      document.body.style.overflow = "visible";
    }

    // Cleanup function to ensure the style is removed when component unmounts or updates
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [menuOpen]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: musicbeats,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const successOptions = {
    loop: true,
    autoplay: true,
    animationData: successanimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  // Prepare the data
  useEffect(() => {
    const reversedData = viralIndiaSongsData.slice().reverse();
    const repeatCount = 3;
    const newData = Array.from({ length: repeatCount }, () => reversedData).flat();
    setRepeatedData(newData);
  }, [viralIndiaSongsData]);

  // Set initial scroll position
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && repeatedData.length > 0) {
      const segmentWidth = scrollContainer.scrollWidth / 3;
      scrollContainer.scrollLeft = segmentWidth;
    }
  }, [repeatedData]);

  // Handle scroll for infinite effect
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      const totalScrollWidth = scrollContainer.scrollWidth;
      const segmentWidth = totalScrollWidth / 3;
      const maxScrollLeft = totalScrollWidth - scrollContainer.clientWidth;
      const scrollLeft = scrollContainer.scrollLeft;

      if (scrollLeft <= 0) {
        scrollContainer.scrollLeft += segmentWidth;
      } else if (scrollLeft >= maxScrollLeft) {
        scrollContainer.scrollLeft -= segmentWidth;
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [repeatedData]);

  // useEffect(() => {
  //   const generateRandomSlice = (data, sliceCount = 5) => {
  //     // Generate random number `b`
  //     const b = Math.floor(Math.random() * (data.length - sliceCount)); // Ensure b + sliceCount doesn't exceed length
  //     const a = b + sliceCount; // a will always be b + sliceCount (5)

  //     return data.slice(b, a);
  //   };

  //   if (trendingSongsData.length > 0) {
  //     setTrendingSlice(generateRandomSlice(trendingSongsData));
  //   }
  //   if (topSongsData.length > 0) {
  //     setInternationalSlice(generateRandomSlice(topSongsData));
  //   }
  // }, [trendingSongsData, topSongsData]);
  //For RANDOMNESS PER RELOAD
  useEffect(() => {
    // Function to generate and store a random index for the current session
    const generateRandomIndex = (key, data, sliceCount = 5) => {
      // Check if the value already exists in sessionStorage
      const existingValue = sessionStorage.getItem(key);
      let randomIndex;

      if (existingValue) {
        // Use the existing value from sessionStorage if available
        randomIndex = parseInt(existingValue, 10);
      } else {
        // Generate a new random index within the allowable range
        randomIndex = Math.floor(Math.random() * (data.length - sliceCount));
        // Store the value in sessionStorage for this session
        sessionStorage.setItem(key, randomIndex.toString());
      }

      // Ensure the end index `a` doesn't exceed the length of the array
      const a = randomIndex + sliceCount;

      return data.slice(randomIndex, a);
    };

    // Check if we have valid data and update slices accordingly
    if (trendingSongsData.length > 0) {
      setTrendingSlice(generateRandomIndex('trendingSliceIndex', trendingSongsData));
    }
    if (topSongsData.length > 0) {
      setInternationalSlice(generateRandomIndex('internationalSliceIndex', topSongsData));
    }
  }, [trendingSongsData, topSongsData]);

  return (
    <AppWrapper onClick={toggleMenu}>
      <AppContent $menuOpen={menuOpen}>
        <SearchSection onSubmit={handleSearchSubmit}>
          <SearchBar>
            <SearchIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5ad0efe60d842d132bfbba6de074812774d978a00f0c3d2c92ade9ffa633aa8?apiKey=4821a7757e194ceda4e8cb53fcb29f4a&"
              alt="Search icon"
            />
            <SearchInput
              type="text"
              placeholder="What do you want to listen to?"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={() => {
                ReactGA.event('dp_search_tap',
                  {
                    category: "User",
                    action: "dp_search_tap",
                    label: venueId,
                  });
              }}
            />
            {searchQuery && (
              <ClearButton onClick={handleClearSearch} type="button" />
            )}
          </SearchBar>
          <SearchResults>
            {searchResults.map((result) => (
              <SearchResultItemComponent
                key={result.id}
                result={result}
                onToggleSelect={handleToggleSelect}
                isSelected={selectedSongs.includes(result)}
                source={"search"}
                handleSendToQueue={handleSendToQueue}
                onThumbnailClick={(event, song) => {
                  ReactGA.event('dp_search_result_thumbnail_tap',
                    {
                      category: "User",
                      action: "dp_search_result_thumbnail_tap",
                      label: venueId,
                      song_details: formatSongDetails(song),
                    });
                }}
              />
            ))}
          </SearchResults>
        </SearchSection>



        <ToastContainer />

        {!isSearching && (
          <LandingContainer>
            {recentlyPlayedData.length >= 3 ? (
              <>
                <SectionTitle>Recently Played</SectionTitle>
                <HorizontalScroll>
                  {recentlyPlayedData.slice(0, 6).map((song) => (
                    <Item key={song.id}>
                      <ItemImage src={song.album.images[0].url} alt="Song Thumbnail" />
                      <ItemInfo>
                        <ItemTitle>{song.name}</ItemTitle>
                        <ItemArtist>{song.artists[0].name}</ItemArtist>
                      </ItemInfo>
                    </Item>
                  ))}
                </HorizontalScroll>
              </>
            ) : (
              <>
                <SectionTitle>You May Like</SectionTitle>
                <AnimatedHorizontalScroll ref={scrollContainerRef}>
                {repeatedData.map((song, index) => (
                    <AnimatedItem // Use AnimatedItem for the animated effect
                      // key={song.track.id}
                      onClick={(event) => handleToggleSelect(event, song.track, "youmaylike")}
                    >
                      <div style={{ position: 'relative' }}>  {/* Additional relative positioning container */}
                        <ItemImage
                          src={song.track.album.images[0].url}
                          alt="Song Thumbnail"
                          style={{
                            opacity: selectedSongs.includes(song.track) ? 0.5 : 1, // Apply opacity only to the image
                          }}
                        />
                        {selectedSongs.includes(song.track) && (
                          <FaCheck style={{
                            position: 'absolute',
                            color: "#FFBA33",
                            bottom: '10px',  // Adjust these as necessary to match your design
                            right: '10px',
                            fontSize: '14px'
                          }} />
                        )}
                      </div>
                      <ItemInfo>
                        <ItemTitle>{song.track.name}</ItemTitle>
                        <ItemArtist>{song.track.artists[0].name}</ItemArtist>
                        {selectedSongs.includes(song.track) && (
                          <AddToQueueHorizontal onClick={() => handleSendToQueue()}>
                            {/* <PlaylistAddIcon /> */}
                            Add
                          </AddToQueueHorizontal>
                        )}
                      </ItemInfo>
                      </AnimatedItem>
                  ))}
                  </AnimatedHorizontalScroll>
              </>
            )}
            <SectionTitle style={{ marginTop: '0px' }}>Trending Now</SectionTitle>
            <ItemList>
            {trendingSlice.map((song) => (
                <SearchResultItemComponent
                  key={song.track.id}
                  result={song.track}
                  onToggleSelect={handleToggleSelect}
                  isSelected={selectedSongs.includes(song.track)}
                  source={"trendingnow"}
                  handleSendToQueue={handleSendToQueue}
                />
              ))}
            </ItemList>
            <SectionTitle>International Hits</SectionTitle>
            <ItemList>
            {internationalSlice.map((song) => (
                <SearchResultItemComponent
                  key={song.track.id}
                  result={song.track}
                  onToggleSelect={handleToggleSelect}
                  isSelected={selectedSongs.includes(song.track)}
                  source={"internationalhits"}
                  handleSendToQueue={handleSendToQueue}
                />
              ))}
            </ItemList>
          </LandingContainer>
        )}
      {/* {isFreezeModeActive && <FreezeMode />} */}

        {/* <AddToQueueButton
          onClick={handleSendToQueue}
          $isBottomBarVisible={livePLaylist.length > 0}
          $isVisible={selectedSongs.length > 0} // Button only appears if there's at least one song selected
        >
          Add to Live Playlist
        </AddToQueueButton> */}

        {/* The Playlist page overlay */}
        <PlaylistOverlay style={{ transform: `translateY(${100 - barPosition}%)` }}>
          {/* Your Playlist component or a preview of it */}
          <h2 style={{ color: '#fff' }}>Playlist Page Content</h2>
          {/* Add the actual playlist content or a preview */}
        </PlaylistOverlay>

        <BottomBar id="bottomBar" style={{ transform: `translateY(-${barPosition}px)` }} onClick={handleBarClick}>
          {isBarActive && livePLaylist.length > 0 ? (
            <>
              <BarAlbumImage src={livePLaylist[0]?.songObj?.album?.images[0]?.url || 'default-album-image.png'} alt="Album" />
              <BarSongInfo>
                <BarSongTitle>{livePLaylist[0]?.songObj.name || 'Song Title'}</BarSongTitle>
                <BarArtistName>{livePLaylist[0]?.songObj.artists[0]?.name || 'Artist Name'}</BarArtistName>
              </BarSongInfo>
              <MusicLottieContainer>
                <Lottie options={defaultOptions} />
              </MusicLottieContainer>
            </>
          ) : (
            <>
              <BarHeaderInfo onClick={handleOfflineClick}>
                <BarTextHeader>{venueName}</BarTextHeader>
                <BarTextSubheader>is currently offline</BarTextSubheader>
              </BarHeaderInfo>
              <MusicLottieContainer onClick={handleOfflineClick}>
                <Timeout />
              </MusicLottieContainer>
            </>
          )}
        </BottomBar>

        {isModalOpen && (
          <>
            <ModalOverlay onClick={handleCloseModal} />
            <Modal>
              <ModalContent>
                <ModalTextHeader>Offline!</ModalTextHeader>
                <ModalImage src={timeoutImg} alt="Timeout Image" />
                <ModalText>{venueName} is offline. Contact the reception for help.</ModalText>
                <CloseButton onClick={handleCloseModal}>Close</CloseButton>
              </ModalContent>
            </Modal>
            </>
          )}

        {showSuccessAnimation && (
          <SuccessLottieContainer>
            <Lottie options={successOptions} height={150} width={150} />
            <SuccessText>Added to Live Playlist</SuccessText>
          </SuccessLottieContainer>
        )}
      </AppContent>
    </AppWrapper>
  );
};

//Animations
const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

const slideInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%); /* Start from the left */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const slideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%); /* Start from the Right */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const AppWrapper = styled.div`
  // background-image: url(${uiBackgroundImg});
  background-color: #141414;
  background-size: cover;
  background-position: center;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height:100vh;
`;

const SoundBar = styled.div`
  background: url(${soundBarImg}) no-repeat center center;
  background-size: cover;
  border: none;
  margin-left: 15%;
  width: 70%;
  height: 40px;
  cursor: pointer;
  left: 0px;
`;

const MenuButton = styled.button`
  background: url(${menuIcon}) no-repeat center center;
  background-size: cover;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: fixed;
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 16px;
  &:hover {
    background-color: #292929;
  }
`;

const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 5%;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.$show ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

const AppContent = styled.div`
  transition: transform 0.3s ease-in-out;
  padding-left: ${(props) => (props.$show ? "250px" : "0")};
  background-color: #141414;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: ${(props) => (props.$menuOpen ? "hidden" : "auto")};
  overflow-y: auto;
  padding-bottom: 100px;
  height: 100vh;
`;

const Header = styled.header`
  top: 0;
  left: ${(props) =>
    props.$menuOpen ? "250px" : "0"}; // Adjust position based on menu state
  right: 0;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100% // Adjust width based on menu state
  transition: transform 0.3s ease-in-out;// Smooth transition for the movement
`;

// const MenuIcon = styled.img`;
//   width: 35px;
// `;

const SearchSection = styled.form`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 70px;
  position: fixed;
  background-color: #141414;
  width: 100%;
  z-index: 900;
`;

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
  width: 86%;
`;

const SearchIcon = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
`;

const ClearButton = styled.button`
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const ClearIcon = styled.img`
  width: 20px;
  z-index: 100;
  // color: black;
`;

// const SearchResults = styled.div`
//   margin-top: 20px;
// `;

// const SearchResultItem = styled.div`
//   background-color: #f8f8f8;
//   border-radius: 10px;
//   margin-top: 10px;
//   padding: 10px;
//   display: flex;
//   align-items: center;
// `;

// const ResultImage = styled.img`
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   margin-right: 10px;
// `;

// const ResultText = styled.span`
//   font-size: 16px;
// `;

const LandingContainer = styled.div`
  margin-top: 110px;
`

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-right: 18px;
  border-bottom: 60px;
  background-color: #141414; // Dark background
  overflow-y: auto; // Enable vertical scrolling
  max-height: 100vh;
`;

const SearchResultItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0; 
  align-items: centre;
  gap: 10px;
  padding: 8px;
  padding-right: 20px;
  border-radius: 8px;
  color: #ffffff;
  height: auto;
  min-height: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, max-height 0.3s ease;
  overflow: hidden;
  animation: ${fadeInUp} 0.5s ease-in-out;
  animation-fill-mode: both;

`;

const ItemTopRow = styled.div`
  display: flex;
  align-items: center;
`;

const SearchResultItemComponent = ({ result, onToggleSelect, isSelected, source, handleSendToQueue,index }) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const handleImageClick = (event) => {
    event.stopPropagation();

    const previewUrl = result.preview_url;

    if (!previewUrl) return;

    if (currentlyPlaying === result.id.videoId) {
      // If the same song is clicked, stop it
      if (currentAudio) {
        currentAudio.pause();
        setCurrentlyPlaying(null);
        setCurrentAudio(null);
      }
    } else {
      // If a different song is clicked, stop the current one and play the new one
      if (currentAudio) {
        currentAudio.pause();
      }

      const newAudio = new Audio(previewUrl);
      newAudio.play();
      setCurrentAudio(newAudio);
      setCurrentlyPlaying(result.id.videoId);

      // Add event listeners to update the timer
      newAudio.ontimeupdate = () => {
        setCurrentTime(newAudio.currentTime);
      };

      newAudio.onended = () => {
        setCurrentlyPlaying(null);
        setCurrentAudio(null);
        setCurrentTime(0);
      };
    }
  };
  return (
    <SearchResultItem
      isSelected={isSelected}
      style={{ '--animation-order': index }}
    >
        <ItemTopRow>
        <ResultImage
          src={result.album.images[0].url}
          alt="Video Thumbnail"
        // onClick={handleImageClick}
        />
        {/* TODO : check for multiple artists inclusion to be done */}
        {/* <ResultText onClick={(event) => onToggleSelect(event, result)}>{result.name} - {result.artists[0].name}</ResultText> */}
        <ItemInfoVertical onClick={(event) => onToggleSelect(event, result, source)}>
          <ItemTitleVerticalList>{result.name}</ItemTitleVerticalList>
          <ItemArtistVertical>{result.artists[0].name}</ItemArtistVertical>
        </ItemInfoVertical>
        <SelectButton
          type="button"
          onClick={(event) => onToggleSelect(event, result, source)}
        >
          {!isSelected ? <FaPlus /> : null}
        </SelectButton>
      </ItemTopRow>
      {isSelected && (
        <AddToQueueButton
          onClick={() => handleSendToQueue()}
        >
          Add to Live Playlist
        </AddToQueueButton>
      )}
    </SearchResultItem>
  );
};

const SelectButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; // Prevent shrinking
`;

const ResultImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 8px;
  flex-shrink: 0;
  transition: transform 0.3s;
  }
`;

const ResultText = styled.div`
  font-size: 16px;
  color: #ccc; // Light gray text for better readability
  flex-grow: 1;
`;

const SectionTitle = styled.h2`
  color: #fff;
  padding: 10px 16px;
  font-size: 18px;
  margin-bottom: 0px;
  padding-top: 20px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const HorizontalScroll = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  white-space: nowrap;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  & > div:first-child {
    margin-left: 16px; /* Add left margin to the first item */
  }

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;
const AnimatedHorizontalScroll = styled(HorizontalScroll)`
  animation: ${slideInFromLeft} 1s ease-in-out backwards;
`;

const Item = styled.div`
  display: inline-block;
  margin-right: 16px;
  text-align: center;
  cursor: pointer;
  flex: 0 0 auto; // Ensure items do not shrink
`;

const AnimatedItem = styled(Item)`
  animation: ${slideInFromRight} 1s ease-in; /* Animate the sliding in */
`;

const ItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const ItemInfoVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: flex-start;
  margin-top: 2.5%;
  margin-left: 2.5%;
  overflow: hidden;
`;

const ItemTitleVerticalList = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-bottom: 0.25rem;
  max-width: calc(100% - 25px); // Adjust width to not overflow on SelectButton
`;

const ItemArtistVertical = styled.div`
  font-size: 13px;
  color: #9ea3b8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2%;
  max-width: calc(100% - 40px); // Adjust width to not overflow on SelectButton
`;

const ItemInfo = styled.div`
  margin-top: 8px;
  color: #fff;
`;

const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; // Adjust the width based on your design
`;

const ItemArtist = styled.div`
  font-size: 12px;
  color: #9ea3b8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2%;
  max-width: 100px; // Adjust the width based on your design
`;

// const AddToQueueButton = styled.button`
//   position: fixed;
//   bottom: ${(props) => (props.$isBottomBarVisible ? '90px' : '20px')};  // Keeps the button at the bottom of the viewport
//   left: 50%;
//   transform: translateX(-50%);
//   background: linear-gradient(to bottom right, #FFBA33, #664A14); // Gradient from #FFC629 to black
//   color: white;
//   border: none;
//   border-radius: 40px;
//   padding: 12px 20px; // Comfortable padding
//   width: 90%; // Almost full width of the screen
//   font-size: 16px; // Bold and readable font size
//   font-weight: bolder;
//   text-align: center;
//   cursor: pointer;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly stronger shadow for depth
//   transition: background-color 0.3s, opacity 0.3s;
//   display: ${(props) => (props.$isVisible ? "block" : "none")};
//   z-index:980;

//   &:active {
//     background: linear-gradient(90deg, rgba(69, 160, 73, 1), black); // Darker gradient when tapped or clicked
//     opacity: 0.9; // Slightly less opaque to simulate a press effect
//   }

//   &:focus {
//     outline: none; // Avoids the default focus outline
//   }
// `;

const AddToQueueButton = styled.button`
  margin-top: 8px;
  width: 100%;
  background: linear-gradient(to bottom right, #FFBA33, #664A14);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s, transform 0.2s;

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }

  &:focus {
    outline: none;
  }
`;

const AddToQueueHorizontal = styled(AddToQueueButton)`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaylistOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;  // Full height
  background-color: #121212;
  z-index: 999;  // Just below the BottomBar's z-index
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(18, 18, 18, 0.85); /* Uniform darker background with opacity */
  backdrop-filter: blur(25px); /* Frosted glass effect */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.6), 0 2px 10px rgba(255, 255, 255, 0.1);
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isActive ? 0 : 1)};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  animation: ${fadeInUp} 0.5s ease-in-out;
`;

const BarAlbumImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
  margin-left: 12px;
`;

const BarSongInfo = styled.div`
  flex-grow: 1;
  margin-left: 15px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const BarSongTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const BarArtistName = styled.span`
  font-size: 14px;
  color: #b3b3b3;
`;

const BarHeaderInfo = styled.div`
  flex-grow: 1;
  margin-left: 21px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const BarTextHeader = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const BarTextSubheader = styled.span`
  font-size: 16px;
  color: #b3b3b3;
`;


const MusicLottieContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 40px;
`;

const Timeout = styled.div`
  background: url(${timeoutImg}) no-repeat center center;
  background-size: cover;
  border: none;
  margin-left: 15%;
  width: 70%;
  height: 40px;
  cursor: pointer;
  left: 0px;
  margin-top: 10px;
`;

const SuccessLottieContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;  // Ensure it's on top of everything
  background-color: rgba(0, 0, 0, 0.8);  // Optional background to make it stand out
  padding: 20px;
  border-radius: 10px;
  isplay: flex;
  flex-direction: column;  // Stack animation and text vertically
  align-items: center;  // Center-align the content
  justify-content: center;
  animation: ${fadeIn} 0.5s ease-in-out;

`;

const SuccessText = styled.div`
  color: #fff;  // Text color
  font-size: 18px;  // Adjust the size as needed
  font-weight: bold;
  margin-top: 20px;  // Adds spacing between the animation and text
  text-align: center;  // Center-align the text
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent overlay */
  z-index: 1001; /* Ensure it's below the modal but above the rest of the content */
`;

const Modal = styled.div`
   position: fixed;
  top: 50%;
  left: 34%;
  width: 70%;
  transform: translate(-30%, -50%);
  z-index: 2000;  // Ensure it's on top of everything
  background-color: rgba(0, 0, 0, 0.6);  // Optional background to make it stand out
  padding: 20px;
  border-radius: 10px;
  isplay: flex;
  flex-direction: column;  // Stack animation and text vertically
  align-items: center;  // Center-align the content
  justify-content: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  &.closing {
    animation: ${fadeOut} 0.5s ease-in-out;
  }
`;

const ModalContent = styled.div`
flex-direction: column;  // Stack animation and text vertically
  align-items: center;  // Center-align the content
  justify-content: center;
  display:flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalTextHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const ModalImage = styled.img`
  max-width: 100px;
  height: auto;
`;

const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #FFBA33;
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 16px;
`;


export default SpotifySearchComponent;
