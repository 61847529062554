// src/components/MenuIcon.js

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MenuButton = styled(motion.button)`
  background: none;
  border: none;
  width: 15px;
  height: 10px;
  cursor: pointer;
  position: fixed;
  z-index: 1100;
  left: 20px;
  right: 337px;
  top: 24px;
  bottom: 600px;
  padding-top: 1px;
  padding-right: 6px;
  width: 34px;
  height: 21px;
  padding-left: 6px;
  padding-bottom: 1px;

`;

const menuLineStyle = {
  display: 'block',
  width: '20px',
  height: '1.5px',
  backgroundColor: '#fff',
  margin: '5px auto',
  borderRadius: '2px',
};

const MenuIcon = ({ menuOpen, toggleMenu }) => {
  const variant = menuOpen ? 'opened' : 'closed';

  const lineVariants = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 6,
    },
  };

  const line2Variants = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };

  const line3Variants = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -5.9,
    },
  };

  return (
    <MenuButton onClick={toggleMenu}>
      <motion.span
        variants={lineVariants}
        animate={variant}
        style={menuLineStyle}
      />
      <motion.span
        variants={line2Variants}
        animate={variant}
        style={menuLineStyle}
      />
      <motion.span
        variants={line3Variants}
        animate={variant}
        style={menuLineStyle}
      />
    </MenuButton>
  );
};

export default MenuIcon;
