// src/pages/VenuePlayer.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import ReactGA from "react-ga4";
// import SpotifyPlayer from '../utility/SpotifyPlayer'; // Import SpotifyPlayer
import MusicPlayer from '../utility/MusicPlayer';
// import headerLogo from '../../assets/new-menu-icon-2.png';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SkipReason from './SkipReason'; // Import SkipReason component
import { FaTrash } from 'react-icons/fa'; // Import trash icon for skip button

// Import the HeartIconWithCount component
 import HeartIconWithCount from '../PlaylistPage/HeartIconWithCount';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Styled Components for Page Layout
const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  color: #FFFFFF;
  background-color: #121217;
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden; // Prevent horizontal overflow

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 5%;
  box-sizing: border-box; // Include padding in element's total width and height
  padding-left: 5rem;

  @media (max-width: 768px) {
    flex: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    padding-bottom: 0.1rem;
    max-height: 80%;
  }
`;

const RightContainer = styled.div`
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-left: 1px solid #2E2E2E;
  margin-top: 5%;
  box-sizing: border-box; // Include padding in element's total width and height

  @media (max-width: 768px) {
    flex: 1;
    border-left: none;
    border-top: 1px solid #2E2E2E; // Corrected to add a solid border
    padding-top: 0rem;
  }
`;

const UpNextHeader = styled.h2`
  position: sticky;
  top: 1rem; // Adjust this value to ensure it stays below the main header
  background-color: #121217; // Match the page background
  z-index: 5;
  padding: 0.5rem 0;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  padding-bottom: 0;
  background-color: #121217; // Ensure header background color matches the page
  z-index: 10; // Ensure header stays on top
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
  }

  .logo-text {
    font-size: 1.8rem;
    font-weight: bold;
    color: #FFFFFF; // Default color for the "Mutefly" part
  }

  .player-text {
    font-size: 1.94rem;
    color: #38E078; // Green color for the "Player" part
    font-weight: lighter; // Thinner font-weight
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 5rem;
`;

// const SearchInput = styled.input`
//   background-color: #2E2E2E;
//   color: #FFFFFF;
//   border: none;
//   border-radius: 0.75rem;
//   padding: 0.5rem 1rem;
//   outline: none;

//   &::placeholder {
//     color: #999999;
//   }
// `;

const UserProfile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('https://cdn.usegalileo.ai/stability/2560c208-d4b2-476e-b425-e5d98ee66e98.png');
  background-size: cover;
`;

// const Content = styled.div`
//   display: flex;
//   justify-content: center;
//   padding: 5rem 1.5rem 2rem; // Adjust padding to account for fixed header
//   flex: 1;
// `;

const SongListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

// Animation Styles
// const SongItemContainer = styled.div`
//   &.fade-enter {
//     opacity: 0;
//     transform: translateX(-100%);
//   }
//   &.fade-enter-active {
//     opacity: 1;
//     transform: translateX(0);
//     transition: opacity 300ms, transform 300ms;
//   }
//   &.fade-exit {
//     opacity: 1;
//     transform: translateX(0);
//   }
//   &.fade-exit-active {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: opacity 300ms, transform 300ms;
//   }
// `;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Added for vertical alignment */
  padding: 0.75rem;
  border-radius: 0.5rem;
  gap: 1rem;
  background-color: #141414;
  margin-bottom: 0.5rem;
  &:hover {
    background-color: #2E2E2E;
  }
`;

const SongDetails = styled.div`
  display: flex;
  gap: 1rem;
`;

const SongThumbnail = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 0.75rem;
  background-size: cover;
  background-image: url(${props => props.url});
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SongTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0;
`;

const SongArtist = styled.p`
  font-size: 0.875rem;
  color: #999999;
  margin: 0;
  padding-top: 0.5rem;
`;

const ActionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem; // Added gap between skip button and heart icon
`;

// Styled Components for Skip Button
const SkipButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0.75rem;
  padding: 0.5rem;
  color: #FFFFFF;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;

  &:hover {
    color: #FF4D4D; // Change to red on hover
  }
  &:active {
    color: #FF4D4D; // Change to red when pressed
  }
  @media (max-width: 768px) {
    &:active {
      color: #FF4D4D; // Change to red on mobile when pressed
    }
  }
`;

// CSS for animations
const FadeStyles = `
  .fade-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: all 300ms ease-in-out;
  }
`;

// Apply the CSS to a styled component
const StyledSongList = styled.div`
  ${FadeStyles}
`;

// HeartIconWithCount Component is imported above

// Existing SongList component (modified)
const SongList = ({ queue, onSkip, venueId, sessionId }) => {
  const handleSongTap = (song) => {
    // Implement song tap functionality if needed
  };

  return (
    <StyledSongList>
      <TransitionGroup>
        {queue.filter((_, index) => index !== 0).map((song, index) => (
          <CSSTransition key={song.songObj.id} timeout={300} classNames="fade">
            <SongItem onClick={() => handleSongTap(song)}>
              <SongDetails>
                <SongThumbnail url={song.songObj.album.images[0].url} />
                <SongInfo>
                  <SongTitle>{song.songObj.name}</SongTitle>
                  <SongArtist>{song.songObj.artists[0].name}</SongArtist>
                </SongInfo>
              </SongDetails>
              <ActionArea>
                {/* Integrate HeartIconWithCount here */}
                <HeartIconWithCount
                  venueId={venueId}
                  entryId={song.entryId} // Ensure song has entryId
                  initialLikes={song.likesCount || 0} // Use updated likesCount
                  sessionId={sessionId} // Ensure sessionId is available
                  isInteractive={false} // Set to false for read-only mode
                />
                <SkipButton onClick={() => onSkip(index + 1)} title="Skip" aria-label="Skip">
                  <FaTrash />
                </SkipButton>
              </ActionArea>
            </SongItem>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledSongList>
  );
};

// Function to get user profile from Spotify
async function getUserProfile(accessToken) {
  const response = await fetch('https://api.spotify.com/v1/me', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return response.json();
}

function VenuePlayer() {
  const [songQueueData, setSongQueueData] = useState([]);
  const [token, setToken] = useState('');
  const [refresh_token, setRefresh_token] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const venueId = new URLSearchParams(window.location.search).get('venueId');

  // Assume sessionId is obtained here. Replace with actual implementation.
  const sessionId = useState('unique-session-id')[0]; // Example placeholder

  useEffect(() => {

    let refreshTokenInterval;
    ReactGA.event('mp_page_view', {
      category: 'User',
      action: 'mp_page_view',
      label: venueId
    });

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const fetchToken = async () => {
      try {

        try {
          const response = await fetch(`${backendUrl}/api/venue/tokens/${venueId}`);
          const data = await response.json();

          if (response.ok) {
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;
            // console.log('Access Tokenn:', data.accessToken);
            // console.log('Refresh Tokenn:', data.refreshToken);

            if (refreshToken) {
              const response = await axios.get(`${backendUrl}/api/spotify/refresh_token/${venueId}?refresh_token=${refreshToken}`);
              setToken(response.data.access_token);
              // console.log("token: " + response.data.access_token);
              // localStorage.setItem('access_token', response.data.access_token);
            } else {
              // TODO: need some communication here
              window.location.href = `/venuelogin?venueId=${venueId}`;
            }

            // Start the interval to refresh token every 50 minutes
            if (refreshToken) {
              refreshTokenInterval = setInterval(async () => {
                try {
                  // const refreshedResponse = await axios.get(`${backendUrl}/api/spotify/refresh_token/${venueId}?refresh_token=${refreshToken}`);
                  // setToken(refreshedResponse.data.access_token);
                  setTokenRefreshed(true);
                } catch (error) {
                  console.error('Error refreshing token:', error);
                  window.location.href = `/venue?venueId=${venueId}`;
                }
              }, 50 * 60 * 1000); // 50 minutes in milliseconds
            }
          } else {
            console.error('Failed to fetch tokens:', data.error);
          }
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }

      } catch (error) {
        console.error('Error fetching or refreshing token:', error);
        window.location.href = `/venue?venueId=${venueId}`;
      }
    };

    // window.location.href = `${backendUrl}/api/spotify/login/${venueId}`;

    if (!venueId) {
      console.error('No venueId found in the URL');
      window.location.href = '/venuelogin';
    }

    fetchToken();

    return () => clearInterval(refreshTokenInterval);  // Clear the interval when the component unmounts
  }, [venueId]);

  const reloadParentPage = () => {
    setShouldReload(true);
    window.location.reload();
  };

  useEffect(() => {
    const handleSendToQueue = async (songRec) => {
      if (!songRec) return;
      const timestamp = new Date().toISOString();
      const response = await fetch(`${backendUrl}/queue/add/${venueId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ song: [songRec] }),
      });
      if (response.status === 200) {
        console.log("Song added! Keep the vibe going.");
      } else {
        console.log("Error adding song to queue.");
      }
    };
    const fetchSongQueueData = () => {
      axios.get(`${backendUrl}/playerqueue/${venueId}`)
        .then(response => {
          setSongQueueData(response.data);
          if (response.data.length === 1) {
            //fetchRecommendation(response.data[0]);
            // fetchRadio(response.data[0]);
          }
        })
        .catch(error => {
          console.error('Error fetching song queue:', error);
        });
    };

    // const fetchRecommendation = async (lastPlayed) => {
    //   const seed_artists = lastPlayed.artists[0].id;
    //   const seed_track = lastPlayed.id;
    //   try {
    //     const response = await axios.get(`${backendUrl}/api/spotify/recommendation?seed_artists=${seed_artists}&seed_tracks=${seed_track}`);
    //     handleSendToQueue(response.data);
    //   } catch (error) {
    //     console.error('Error fetching recommendation:', error);
    //   }
    // };

    // const fetchRadio = async (lastPlayed) => {
    //   const songName = lastPlayed.name;
    //   const artistName = lastPlayed.artists[0].name;
    //   const searchQuery = songName + " Radio";
    //   try {
    //     // Backend call which fetches the radio, then finds the second song from the playlist, returns, adds it to the queue
    //     const response = await axios.get(`${backendUrl}/api/spotify/radio?searchQuery=${searchQuery}&artistName=${artistName}`);
    //     handleSendToQueue(response.data);
    //   } catch (error) {
    //     console.error('Error fetching radio:', error);
    //   }
    // }

    if (!venueId) {
      console.error('No venueId found in the URL');
      window.location.href = '/venuelogin';
    }

    fetchSongQueueData();
    const intervalId = setInterval(fetchSongQueueData, 5000);
    return () => clearInterval(intervalId);
  }, [token, venueId]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await getUserProfile(token);
      if (profile.images && profile.images.length > 0) {
        setProfileImage(profile.images[0].url);
      }
    };

    fetchUserProfile();
  }, [token]);

  const handleSkipSong = (index) => {
    ReactGA.event('mp_skip_btn_click', {
      category: 'Venue',
      action: 'mp_skip_btn_click',
      label: venueId,
    });
    setSelectedSongIndex(index);
    setIsSkipDialogOpen(true);
  };

  const handleSubmitSkipReason = async (reason) => {
    if (selectedSongIndex !== null) {
      try {
        await axios.post(`${backendUrl}/queue/remove/${venueId}`, {
          index: selectedSongIndex,
          reason: reason, // Send the reason for skipping (can be null)
        });
        // Fetch the updated queue from the backend
        const updatedQueueResponse = await axios.get(`${backendUrl}/playerqueue/${venueId}`);
        setSongQueueData(updatedQueueResponse.data);
      } catch (error) {
        console.error('Error submitting skip reason:', error);
      }
    }
    // Reset dialog state
    setIsSkipDialogOpen(false);
    setSelectedSongIndex(null);
  };

  return (
    <PageContainer>
      <Header>
        <Logo>
          {/* <img src={headerLogo} alt="Mutefly Logo" style={{ width: '2rem', height: '2rem' }} /> */}
          <h2 className="logo-text">Mutefly.</h2><h2 className="player-text">Player</h2>
        </Logo>
        <SearchContainer>
          {/* <SearchInput placeholder="Search" /> */}
          <UserProfile style={{ backgroundImage: `url(${profileImage})` }} />
        </SearchContainer>
      </Header>
      <LeftContainer>
        <MusicPlayer
          token={token}
          queue={songQueueData}
          venueId={venueId}
          tokenRefreshed={tokenRefreshed}
          reloadParentPage={reloadParentPage}
          refreshToken={refresh_token}
        />
      </LeftContainer>
      <RightContainer>
        <UpNextHeader>Up Next</UpNextHeader>
        <SongListContainer  onScroll={() => {
            const scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
            ReactGA.event('mp_song_list_scroll', {
              category: 'User',
              action: 'mp_song_list_scroll',
              label: venueId,
              scroll_depth: scrollDepth,
            });
          }}>
          <SongList 
            queue={songQueueData}
            onSkip={handleSkipSong}
            venueId={venueId}
          />
        </SongListContainer>
        {isSkipDialogOpen && (
          <SkipReason
            isOpen={isSkipDialogOpen} // Pass dialog open state
            onClose={() => setIsSkipDialogOpen(false)} // Close dialog function
            onSubmit={handleSubmitSkipReason}
          />
        )}
      </RightContainer>
    </PageContainer>
  );
}

export default VenuePlayer;
